<template>
  <center>
    <div class="error-404">
      <h1>الخطأ 404: الصفحة غير موجودة</h1>
      <p>
        <a href="/">العودة إلى الصفحة الرئيسية</a>
      </p>
    </div>
  </center>
</template>

<script>

  export default {
    name: 'NotFound'
  }

</script>

<style lang="scss" scoped="">
  @import '@/scss/_variables.scss';

  center {
    margin: 15vw;
  }

  h1 {
    color: var(--border);
    font-size: 2em;
    padding-bottom: 20px;
  }

  .error-404 {
      padding-top: 50px;
  }


</style>